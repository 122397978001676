<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" md="7">
        <h2 class="text-h4 text-primary mb-4 font-weight-bold">Tentang Kami</h2>
        <p class="text-body-1 mb-4 text-grey-darken-1">
          Gotong royong Indonesia atau
          <a
            href="https://gotongroyong.org/"
            target="_blank"
            rel="noopener"
            aria-label="Website Gotong Royong Indonesia"
          >
            gotongroyong.org
          </a>
          adalah sebuah platform yang mewadahi kegiatan-kegiatan sosial yang
          dilakukan dalam bingkai kerelawanan (voluntary), beranggotakan muda
          mudi Nusantara, yang berpusat di Daerah Istimewa Yogyakarta.
        </p>
        <p class="text-body-1 text-grey-darken-1">
          Secara umum Gotong Royong Indonesia bertujuan untuk mengadvokasi dan
          mengamplifikasi kegiatan-kegiatan sosial positif yang langsung
          berdampak kepada masyarakat, dan secara khusus menghadirkan platform
          digital yang menjadi solusi untuk mempercepat dan memudahkan pembuat
          maupun peserta kegiatan sosial agar lebih terdokumentasi dan terdata.
          Mewujudkan gerakan kerelawanan yang modern, transparan dan bertumbuh.
        </p>
      </v-col>
      <v-col cols="12" md="5">
        <v-img
          src="/assets/images/dokumentasi-gotong-royong.webp"
          :srcset="getSrcSet('/assets/images/dokumentasi-gotong-royong')"
          alt="Dokumentasi kegiatan Gotong Royong Indonesia"
          class="rounded-lg mx-auto"
          width="100%"
          max-width="500px"
          height="350px"
          aspect-ratio="1.43"
          cover
          eager
          :sizes="'(max-width: 600px) 100vw, 500px'"
          loading="eager"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                indeterminate
                color="primary"
                aria-label="Loading image"
              />
            </div>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
// Function to generate srcset for responsive images
const getSrcSet = (basePath: string) => {
  return `
    ${basePath}-sm.webp 400w,
    ${basePath}-md.webp 768w,
    ${basePath}.webp 1200w
  `;
};
</script>

<style scoped>
/* Enhance text readability */
p {
  line-height: 1.6;
  max-width: 65ch; /* Optimal reading width */
}

a {
  transition: color 0.2s ease;
  text-decoration: underline;
  text-underline-offset: 2px;
}

a:hover {
  text-decoration-thickness: 2px;
}

/* Ensure image maintains proper aspect ratio even when loading */
:deep(.v-img) {
  contain: strict;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
</style>
